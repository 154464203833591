var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-tabs', {
    attrs: {
      "content-class": "pt-1",
      "fill": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "active": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "UserIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Konsumen")])];
      },
      proxy: true
    }])
  }, [_c('alamat-konsumen')], 1), _vm.isCabang || _vm.isMarketing ? _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "StoreIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Toko")])];
      },
      proxy: true
    }], null, false, 3853453577)
  }, [_c('alamat-toko')], 1) : _vm._e(), _vm.isCabang || _vm.isMarketing ? _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "StoreIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Retur")])];
      },
      proxy: true
    }], null, false, 1820211954)
  }, [_c('alamat-retur')], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }